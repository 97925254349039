



























































































































import {
  computed,
  defineComponent,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import useBase64 from "@/use/base64";
import validator from "validator";

interface StepError {
  step: number;
  error: string;
}

export default defineComponent({
  components: {
    OLocationDataForm: () =>
      import(
        "@/components/organisms/globalTools/location/o-location-data-form.vue"
      ),
    OLocationAddressForm: () =>
      import(
        "@/components/organisms/globalTools/location/o-location-address-form.vue"
      ),
    OLocationContactForm: () =>
      import(
        "@/components/organisms/globalTools/location/o-location-contact-form.vue"
      ),
    OLocationSummary: () =>
      import(
        "@/components/organisms/globalTools/location/o-location-summary.vue"
      ),
  },

  setup(_, { root }) {
    const model = reactive<{
      stepper: number;
      data: any;
      address: any;
      contact: any;
    }>({
      stepper: 1,
      data: {
        language: "polish",
        category: "brak",
      },
      address: {},
      contact: {},
    });

    const { toBase64 } = useBase64();

    const dataSummary = computed(() => ({
      data: model.data,
      address: model.address,
      contact: model.contact,
    }));

    const state = reactive({
      loading: false,
      success: false,
      error: false,
      errors: computed((): StepError[] => {
        const errors: StepError[] = [];

        if (!model.data.name)
          errors.push({
            step: 1,
            error: "Podaj nazwę obiektu konferencyjnego.",
          });
        if (!model.address.city)
          errors.push({ step: 2, error: "Podaj miasto." });
        if (model.contact.website && !validator.isURL(model.contact.website))
          errors.push({ step: 3, error: root.$tc("layout.misc.validUrl") });
        return errors;
      }),
      locationPhoto: "",
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const isStepEditable = (step: number) => {
      // return model.stepper > step
      return true;
    };

    const isStepComplete = (step: number) => {
      return (
        model.stepper > step &&
        !state.errors.find((error) => error.step == step)
      );
    };

    const isStepValid = (step: number) => {
      return !state.errors.find((error) => error.step == step);
    };

    const isFormValid = computed(() => !state.errors.length);

    const goToErrors = () => {
      let lowest = Number.POSITIVE_INFINITY;
      let highest = Number.NEGATIVE_INFINITY;

      let tmp: number;

      for (let i = state.errors.length - 1; i >= 0; i--) {
        tmp = state.errors[i].step;
        if (tmp < lowest) lowest = tmp;
        if (tmp > highest) highest = tmp;
      }

      model.stepper = lowest;
    };

    watch(
      () => model.data.photo,
      async (newPhoto) => {
        state.locationPhoto = (await toBase64(newPhoto)) as string;
      }
    );

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      let imageId = "";
      const formData = new FormData();

      formData.append("image", model.data.photo);

      if (model.data.photo) {
        await axiosInstance
          .post("image/location", formData)
          .then(({ data: { id } }) => {
            imageId = id;
          })
          .catch((e) => console.log(e));
      }

      const data = {
        name: model.data.name,
        languageVersion: model.data.language || undefined,
        category: model.data.category || undefined,
        description: model.data.description || undefined,
        image: imageId || undefined,
        city: model.address.city,
        street: model.address.address || undefined,
        postalCode: model.address.postalCode || undefined,
        latitude: model.address.map
          ? model.address.point.lat || undefined
          : undefined,
        longitude: model.address.map
          ? model.address.point.lng || undefined
          : undefined,
        phoneNumber: model.contact.phone || undefined,
        email: model.contact.email || undefined,
        website: model.contact.website || undefined,
      };

      state.loading = true;

      axiosInstance
        .post("location", data)
        .then(() => {
          state.success = true;
          model.stepper = 1;
          model.data = {
            language: "polska",
            category: "brak",
          };
          (model.address = {}), (model.contact = {});
        })
        .catch((error) => {
          state.error = error.response.status;
        })
        .finally(() => (state.loading = false));
    };

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return "Brak uprawnień";
        case 404:
          return "Endpointu nie znaleziono";
        case 409:
          return "Obiekt już istnieje";
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return "Wystąpił bład";
      }
    };

    return {
      state,
      model,
      isStepEditable,
      isStepComplete,
      isStepValid,
      isFormValid,
      goToErrors,
      dataSummary,
      onSubmit,
      getErrorMessage,
    };
  },
});
